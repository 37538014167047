import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import "./assets/style/main.scss";


import Home from "./pages/Home";

import { useContext, useEffect } from "react";
import ReactGA from 'react-ga';


function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);




  return (
    <div className="App container">
      <Routes>
          <Route path="/" element={<Home />}  />
          <Route path="home/:id" element={<Home />} />
      </Routes>
    </div>
  );
}

export default App;
