import React, { useState, Component, useContext, useEffect, useRef } from "react";
import Navbar from "../SharedComponents/Navbar";
import Footer from "../SharedComponents/Footer";


import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import pomegranate from "../assets/images/Pomegranate.jpg";

import pre_harvest from "../assets/images/pre_harvest.jpg";
import user from "../assets/images/user.jpg";

import phone_call from "../assets/images/phone-call.png";
import pest from "../assets/images/pest.png";
import clouds from "../assets/images/clouds.png";
import drop from "../assets/images/drop.png";
import a_grade from "../assets/images/A.png";
import Farmervideo from "../assets/images/Farmervideo.mp4";
import play_button from "../assets/images/play-pause-02.png";
import pause_button from "../assets/images/play-pause-01.png";
import farm_tour from "../assets/images/farm_tour.mp4";
import bg1 from "../assets/images/BG1-01.jpg";
import bg2 from "../assets/images/BG1-02.jpg";


import get_money from "../assets/images/get-money.png";
import analysis from "../assets/images/analysis.png";
import money_bag from "../assets/images/money-bag.png";

import land_property from "../assets/images/land_property.png";
import damage_crop from "../assets/images/damage_crop.png";
import rivers from "../assets/images/rivers.png";
import image2 from "../assets/images/image2.png";
import perfokinet from "../assets/images/Perfokinet-01.jpg";
import product10 from "../assets/images/product10.png";
import pomegranate_nutriance from "../assets/images/pomegranate_nutriance.jpg";
import pomegranate_background from "../assets/images/pomegranate.jpg";
import banner from "../assets/images/banner.jpg";
import pomegranate_color from "../assets/images/pomegranate-color-02.jpg";
import pome_image from "../assets/images/pome_image.png";
import pome_image1 from "../assets/images/pome_image1.png";
import pome_image2 from "../assets/images/pome_image2.png";
import cooking_pomegranate2 from "../assets/images/cooking-pomegranate2.jpg";
import cooking_pomegranate1 from "../assets/images/cooking-pomegranate1.jpg";
import cooking_pomegranate3 from "../assets/images/cooking-pomegranate3.jpg";
import pom from "../assets/images/pom.png";
import pom2 from "../assets/images/pom2.png";

import image1 from "../assets/images/image1.jpg";
import image2_1 from "../assets/images/BG1-05.jpg";
import image3_1 from "../assets/images/image3.jpg";
import image4_1 from "../assets/images/BG1-04 (1).jpg";
import image4_2 from "../assets/images/BG1-05 (1).jpg";
import image4_3 from "../assets/images/BG1-06.jpg";

import { FaHeart  } from "react-icons/fa";
import { PiStarThin } from "react-icons/pi";
import Table from 'react-bootstrap/Table';
import { getWithAthCallithtext, getWithAuthCallWithErrorResponse, getWithAuthCallWithErrorResponsenew, multipartPostCallWithFormDataResponse, postWithAuthCallWithErrorResponse, simpleGetCall } from "../api/ApiServices";
import ApiConfig from "../api/ApiConfig";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Routes, Route, useParams } from 'react-router-dom';
import Lightbox from "react-image-lightbox"; // Import the Lightbox component
import "react-image-lightbox/style.css"; // Import the lightbox styles
import { Modal, Button } from "react-bootstrap";



const Home = () => {

  const {id} = useParams();
  console.log("id**********",id)

    const fte_id = id;
  
    const image_url = "https://bharatkrushiseva.in/media/"
    const image_url1 = "https://bharatkrushiseva.in/"
    const image_url2 = "https://bharatkrushiseva.in"

    // First video reference and state
    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
  
    // Second video reference and state
    const videoRef1 = useRef(null);
    const [isPlaying1, setIsPlaying1] = useState(false);
  
    // Function to toggle play/pause for the first video
    const handlePlayButtonClick = () => {
      if (videoRef.current.paused) {
        videoRef.current.play();
        setIsPlaying(true);
      } else {
        videoRef.current.pause();
        setIsPlaying(false);
      }
    };

    // Function to toggle play/pause for the second video
    const handlePlayButtonClick1 = () => {
      if (videoRef1.current.paused) {
        videoRef1.current.play();
        setIsPlaying1(true);
      } else {
        videoRef1.current.pause();
        setIsPlaying1(false);
      }
    };


    
    const [selectedRating, setSelectedRating] = useState(null);
    const [showReviewSection, setShowReviewSection] = useState(false);
    const [reviewText, setReviewText] = useState('');

  const handleStarClick = (rating) => {
    setSelectedRating(rating);
  };

  const handleSubmitRating = () => {
    if (selectedRating) {
      setShowReviewSection(true);
    } else {
      alert('Please select a rating.');
    }
  };

 

  const handleReviewSubmit = () => {
  if (reviewText && selectedRating !== null) {
    // Example data submission to the backend
    const reviewData = {
      fte_id:fte_id,
      review: reviewText,
      rating: selectedRating,
    };

    // Replace ApiConfig with your actual config or endpoint
    postWithAuthCallWithErrorResponse(
      ApiConfig.Add_Review_And_Rating, // Define this endpoint in your ApiConfig
      JSON.stringify(reviewData)
    )
      .then((res) => {
        alert(res.json.message); // Assuming response has a message field
        setShowReviewSection(false); // Hide review section after submission
      })
      .catch((error) => {
        console.error('Error:', error);
        alert('There was an error submitting your review.');
      });
  } else {
    alert('Please write a review and select a rating.');
  }
};


  




//  talk to us 
// State to handle form inputs


// api integration start

const [loading, setLoading] = useState(false);
const [fte_data, setFteData] = useState({
  fte_id:fte_id,
});

const [my_fte_data, setMyFteData] = useState([]);
const [alert_count, setAlertCount] = useState([]);

const [color_distribution_field_data, setColorDistributionFieldData] = useState([]);





const [ucs_video_tour, setUcsVideoTour] = useState([]);
console.log("ucs_video_tour",ucs_video_tour)

const [currentVideoIndex, setCurrentVideoIndex] = useState(0);

const handleVideoEnd = () => {
  // Move to the next video if available
  if (currentVideoIndex < ucs_video_tour.length - 1) {
    setCurrentVideoIndex(currentVideoIndex + 1);
  } else {
    // Optionally handle the case when there are no more videos
    console.log('No more videos to play.');
  }
};


useEffect(() => {
  get__Ucs_Video_Tour_And_FteMedia();
},[fte_data])



const get__Ucs_Video_Tour_And_FteMedia = () => {
  console.log("call function ***********************")
  setLoading(true);
  postWithAuthCallWithErrorResponse(
    ApiConfig.Get_Ucs_Video_Tour_And_FteMedia,
    JSON.stringify({ ...fte_data })
  )
    .then((res) => {
      setLoading(false);
      console.log("res.json.ucs_video_tour",res.json.ucs_video_tour)
      setUcsVideoTour(res.json.ucs_video_tour);
      // Initialize the video index to 0
      setCurrentVideoIndex(0);
    })
    .catch((err) => {
      setLoading(false);
      console.error(err);
    });
};

useEffect(() => {

  get_Fte_Data();
  get_Alert_Data();
  get_ColorDistribution_Field_Data();

  get_FteMedia();
  get_UcsAlertData();
  get_CropNutrition();
  get_FarmerImpactSummery();
  get_ProductDetail();
  get_FarmerReviewRating();
  get_FreshneshugerAge();
  get_UcsCropCalender();
  
}, []);

const get_Fte_Data = () => {
  setLoading(true);
  postWithAuthCallWithErrorResponse(
    ApiConfig.Get_FTE_Data,
    JSON.stringify({ ...fte_data })
  )
    .then((res) => {
      setLoading(false);
      setMyFteData(res.json.fte_data);
     
    })
    .catch((err) => {
      setLoading(false);
      console.error(err);
    });
};


const get_Alert_Data = () => {
  setLoading(true);
  postWithAuthCallWithErrorResponse(
    ApiConfig.Get_User_Communication_Alert,
    JSON.stringify({ ...fte_data })
  )
    .then((res) => {
      setLoading(false);
      setAlertCount(res.json);
     
    })
    .catch((err) => {
      setLoading(false);
      console.error(err);
    });
};


const [distribution_field_data, setDistributionFieldData] = useState([]);


 // Function to determine the color based on percentage
 const getGaugeColorClass = (percentage) => {
  if (percentage >= 50) {
    return 'gauge__fill-high'; // High percentage
  } else if (percentage >= 20) {
    return 'gauge__fill-medium'; // Medium percentage
  } else if (percentage >= 10) {
    return 'gauge__fill-low'; // Low percentage
  } else {
    return 'gauge__fill-very-low'; // Very low percentage
  }
};

const [graded_yields_data, setGradedYields] = useState([]);


const [diseases_fields_data, setDiseasesFields] = useState([]);

// Function to determine the color class based on percentage
const getColorClass = (percentage) => {
  if (percentage >= 75) {
    return 'text-success bg-success'; // Green for 75% and above
  } else if (percentage >= 50) {
    return 'text-warning bg-warning'; // Yellow for 50% to 74%
  } else if (percentage >= 25) {
    return 'text-orange bg-orange'; // Orange for 25% to 49%
  } else {
    return 'text-danger bg-danger'; // Red for below 25%
  }
};

const get_ColorDistribution_Field_Data = () => {
  setLoading(true);
  postWithAuthCallWithErrorResponse(
    ApiConfig.Get_Color_Distribution_Field_Data,
    JSON.stringify({ ...fte_data })
  )
    .then((res) => {
      setLoading(false);
      setColorDistributionFieldData(res.json.color_field);
      setDistributionFieldData(res.json.distribution_fields);
      setGradedYields(res.json.graded_yields);
      setDiseasesFields(res.json.diseases_fields);
      get_ConnectCommentHistory();
      
     
    })
    .catch((err) => {
      setLoading(false);
      console.error(err);
    });
};

 



const [get_fte_media, setFteMedia] = useState([]);
console.log('get_fte_media',get_fte_media)

const get_FteMedia = () => {
  setLoading(true);
  postWithAuthCallWithErrorResponse(
    ApiConfig.Get_Ucs_Video_Tour_And_FteMedia,
    JSON.stringify({ ...fte_data })
  )
    .then((res) => {
      setLoading(false);
      setFteMedia(res.json.fte_media);

    })
    .catch((err) => {
      setLoading(false);
      console.error(err);
    });
};


const [get_Connect_Comment_History, setConnectCommentHistory] = useState([]);
console.log('get_Connect_Comment_History',get_Connect_Comment_History)

const get_ConnectCommentHistory = () => {
  setLoading(true);
  postWithAuthCallWithErrorResponse(
    ApiConfig.Get_Advisor_Connect_Comment_History,
    JSON.stringify({ ...fte_data })
  )
    .then((res) => {
      setLoading(false);
      setConnectCommentHistory(res.json.advisory_comments);

    })
    .catch((err) => {
      setLoading(false);
      console.error(err);
    });
};


const [get_ucs_alert_Data, setUcsAlertData] = useState([]);
const [get_ucs_alerts_danger, setUcsAlertsDanger] = useState([]);
const [get_ucs_irrigation, setUcsIrrigation] = useState([]);


console.log('get_ucs_alerts_danger',get_ucs_alerts_danger)

const get_UcsAlertData = () => {
  setLoading(true);
  postWithAuthCallWithErrorResponse(
    ApiConfig.Get_Ucs_Alert_Data,
    JSON.stringify({ ...fte_data })
  )
    .then((res) => {
      setLoading(false);
      setUcsAlertData(res.json.ucs_alerts_daily);
      setUcsAlertsDanger(res.json.ucs_alerts_danger);
      setUcsIrrigation(res.json.ucs_irrigation);

    })
    .catch((err) => {
      setLoading(false);
      console.error(err);
    });
};


const [get_crop_nutrition, setCropNutrition] = useState([]);
const [get_health_benefits, setHealthBenefits] = useState([]);
console.log('get_crop_nutrition',get_crop_nutrition)

const get_CropNutrition = () => {
  setLoading(true);
  postWithAuthCallWithErrorResponse(
    ApiConfig.Get_Crop_Nutrition_Helth_Benefits,
    JSON.stringify({ ...fte_data })
  )
    .then((res) => {
      setLoading(false);
      setCropNutrition(res.json.fruits_nutrients);
      setHealthBenefits(res.json.health_benefits);

    })
    .catch((err) => {
      setLoading(false);
      console.error(err);
    });
};


  const [formData, setFormData] = useState({
    enquiryType: '',
    fullName: '',
    email: '',
    country: '',
    message: '',
    check_subscribe: false,
  });

  const [formErrors, setFormErrors] = useState({});
  const [submissionSuccess, setSubmissionSuccess] = useState(false);
  const [submissionError, setSubmissionError] = useState('');

  // Handle form field changes
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  // Validate the form
  const validateForm = () => {
    let errors = {};

    if (!formData.enquiryType) errors.enquiryType = 'Enquiry Type is required';
    if (!formData.fullName) errors.fullName = 'Full Name is required';
    if (!formData.email) errors.email = 'Email is required';
    else if (!/\S+@\S+\.\S+/.test(formData.email)) errors.email = 'Email is invalid';
    if (!formData.country) errors.country = 'Country is required';
    if (!formData.message) errors.message = 'Message is required';

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate form before submission
    if (validateForm()) {
      setLoading(true);
      setSubmissionError('');
      setSubmissionSuccess(false);

      // Perform form submission logic here (e.g., API call)
      postWithAuthCallWithErrorResponse(
        ApiConfig.Add_Talk_To_Us_Data, // The API endpoint
        JSON.stringify(formData)       // Sending form data as payload
      )
        .then((res) => {
          setLoading(false);
          setSubmissionSuccess(true);
          alert(res.json.message);
          console.log('Form Submitted Successfully', res);
            
          // You can also reset form or handle success logic here
          setFormData({
            enquiryType: '',
            fullName: '',
            email: '',
            country: '',
            message: '',
            check_subscribe: false,
          });
        })
        .catch((err) => {
          setLoading(false);
          setSubmissionError('Failed to submit form. Please try again.');
          console.error('Submission Error:', err);
        });
    }
  };




const [get_product_details, setProductDetails] = useState([]);
console.log('get_product_details',get_product_details)


const get_ProductDetail = () => {
  setLoading(true);
  postWithAuthCallWithErrorResponse(
    ApiConfig.Get_Farm_Input,
    JSON.stringify({ ...fte_data })
  )
    .then((res) => {
      setLoading(false);
      setProductDetails(res.json.product_details);
    })
    .catch((err) => {
      setLoading(false);
      console.error(err);
    });
};




const [farmer_impact_summary, setFarmerImpactSummary] = useState([]);
const [get_envernement_impact, setEnvernementImpact] = useState([]);
const [get_ques_data, setQuesData] = useState([]); // Using your original state names
const [activeQuestionIndex, setActiveQuestionIndex] = useState(0); // For tracking active question

  // Fetch the question data from the API when the component mounts
  useEffect(() => {
    get_FarmerImpactSummery();
  }, []);

  const get_FarmerImpactSummery = () => {
    setLoading(true); // Start loading when API call begins
    postWithAuthCallWithErrorResponse(
      ApiConfig.Get_Farmer_Impact_Summary,
      JSON.stringify({ ...fte_data })
    )
      .then((res) => {
        setLoading(false); // Stop loading after getting response
        setFarmerImpactSummary(res.json.impact_on_farmer);
        setEnvernementImpact(res.json.envernement_impact);
        setQuesData(res.json.ques_data); // Set the question data from API response
      })
      .catch((err) => {
        setLoading(false); // Stop loading on error
        console.error(err);
      });
  };

  // Function to handle option click and switch question
  const handleOptionClick = (option) => {
    setActiveQuestionIndex(option - 1); // Adjusting active question index based on clicked option
  };




const [get_farmer_review_rating, setGetFarmerReviewRating] = useState([]);
console.log('get_farmer_review_rating*****',get_farmer_review_rating)

useEffect(() => {
  if (get_farmer_review_rating?.farmer_testimonial?.video_file) {
    // Update the video source when URL changes
    const videoUrl = `https://bharatkrushiseva.in${get_farmer_review_rating.farmer_testimonial.video_file}?${Date.now()}`;
    const videoElement = videoRef.current;
    
    if (videoElement) {
      videoElement.src = videoUrl; // Update video source
      videoElement.load(); // Load the new video
    }
  }
}, [get_farmer_review_rating]);


useEffect(() => {
  if (ucs_video_tour?.length > 0 && ucs_video_tour[0]?.video_file) {
    // Update the video source when the first video is available in ucs_video_tour
    const videoUrl = `https://bharatkrushiseva.in${ucs_video_tour[0].video_file}?${Date.now()}`;
    const videoElement = videoRef1.current; // videoRef refers to the video player DOM element

    if (videoElement) {
      videoElement.src = videoUrl; // Update video source dynamically
      videoElement.load(); // Load the new video
    }
  }
}, [ucs_video_tour]); // Depend on ucs_video_tour array to update the video whenever new data arrives




// src={
//   videoTour?.video_file
//     ? `${image_url2}${videoTour.video_file}`
//     : "not lodead" // Replace with actual fallback video
// }
// type="video/mp4"


const get_FarmerReviewRating = () => {
  console.log("call video function *****************")
  setLoading(true);
  postWithAuthCallWithErrorResponse(
    ApiConfig.Get_Farmer_Review_Rating,
    JSON.stringify({ ...fte_data })
  )
    .then((res) => {
      setLoading(false);
      setGetFarmerReviewRating(res.json);
    })
    .catch((err) => {
      setLoading(false);
      console.error(err);
    });
};



const [isOpen4, setIsOpen4] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);

  const imageFiles = get_fte_media
    .filter((media) => media.media_type === "image")
    .map((media) => image_url2 + media.media_file);

  const videoFiles = get_fte_media
    .filter((media) => media.media_type === "video")
    .map((media) => image_url2 + media.media_file);

  const openModal = (index) => {
    setSelectedIndex(index);
    setIsOpen4(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const showNextMedia = () => {
    setSelectedIndex((selectedIndex + 1) % get_fte_media.length);
  };

  const showPreviousMedia = () => {
    setSelectedIndex(
      (selectedIndex - 1 + get_fte_media.length) % get_fte_media.length
    );
  };

  const selectedMedia = selectedIndex !== null ? get_fte_media[selectedIndex] : null;



  const [likes, setLikes] = useState(get_farmer_review_rating.farmer_likes);
  const [isLiked, setIsLiked] = useState(false); // To prevent multiple likes

  // Function to handle click on like button
  const handleLikeClick = () => {
    console.log('Like button clicked');
  
    const likes = 1; // Default value for likes
    setLikes(likes); // Set likes state to 1
    setIsLiked(true); // Prevent further likes
  
    // Call the API to update the likes on the server
    postWithAuthCallWithErrorResponse(
      ApiConfig.Add_User_Like_To_Farmer,
      JSON.stringify({ likes, ...fte_data })
    )
    .then((res) => {
      alert(res.json.message)
    })
    .catch((err) => {
      setLoading(false);
      console.error(err);
    });
    
      
  };
  
  
const [get_freshness_suger_age, setFreshnessSugerAge] = useState([]);
const get_FreshneshugerAge = () => {
  setLoading(true);
  postWithAuthCallWithErrorResponse(
    ApiConfig.Get_Freshness_Suger_Age,
    JSON.stringify({ ...fte_data })
  )
    .then((res) => {
      setLoading(false);
      setFreshnessSugerAge(res.json);
    })
    .catch((err) => {
      setLoading(false);
      console.error(err);
    });
};

  

const [isOpen, setIsOpen] = useState(false);
const [isOpen1, setIsOpen1] = useState(false);
const [isOpen2, setIsOpen2] = useState(false);

const handleOpenModal = () => {
  setIsOpen(true);
};

const handleCloseModal = () => {
  setIsOpen(false);
};

const handleOpenModal1 = () => {
  setIsOpen1(true);
};

const handleCloseModal1 = () => {
  setIsOpen1(false);
};


const handleOpenModal2 = () => {
  setIsOpen2(true);
};

const handleCloseModal2 = () => {
  setIsOpen2(false);
};





const getCurrentDate = () => {
  const currentDate = new Date();
  const day = currentDate.getDate().toString().padStart(2, '0');
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
  const year = currentDate.getFullYear();
  return `${year}-${month}-${day}`;
};

const getCurrentTime = () => {
  const currentTime = new Date();
  const hours = currentTime.getHours() % 12 || 12; // Convert to 12-hour format
  const minutes = currentTime.getMinutes().toString().padStart(2, '0');
  const ampm = currentTime.getHours() >= 12 ? 'PM' : 'AM';
  return `${hours}:${minutes} ${ampm}`;
};



const steps = [
  {
    name: 'Harvesting',
    date: '2024-09-13',
    time: '2:00 PM',
  },
  {
    name: 'Shift To BKS WareHouse',
    date: '2024-09-13',
    time: '5:00 PM',
  },
   {
    name: 'Stored In Cold Storage',
    date: '2024-09-13 To 2024-10-15',
    time: '8:00 PM To 9:00 AM',
  },

  {
    name: "Shift To Buyer's WareHouse",
    date: '2024-09-15',
    time: '10:00 AM',
  },

  {
    name: "Delivered To Mafco Market Vashi Mumbai",
    date: '2024-09-16',
    time: '10:00 AM',
  },


 
];

const [activeStep, setActiveStep] = useState(0);

const handleNext = () => {
  if (activeStep < steps.length - 1) {
    setActiveStep((prevStep) => prevStep + 1);
  }
};

const handleBack = () => {
  if (activeStep > 0) {
    setActiveStep((prevStep) => prevStep - 1);
  }
};

const handleReset = () => {
  setActiveStep(0);
};
 

const [get_ucs_calender, setUcsCalenderData] = useState([]);
console.log('get_ucs_calender',get_ucs_calender)
const get_UcsCropCalender = () => {
  setLoading(true);
  postWithAuthCallWithErrorResponse(
    ApiConfig.Get_Ucs_Calender_Data_By_FteId,
    JSON.stringify({ ...fte_data })
  )
    .then((res) => {
      setLoading(false);
      setUcsCalenderData(res.json.calender_activities);
    })
    .catch((err) => {
      setLoading(false);
      console.error(err);
    });
};



  return (
    <div>
       <div className="main">
       <Navbar />
        {/* Modal */}
          <Modal show={isOpen} onHide={handleCloseModal} centered>
            <Modal.Header closeButton>
              <Modal.Title>Pomegranate Salad Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <img src={cooking_pomegranate2} alt="Salad" className="img-fluid mb-3" />
              <p className="text-muted">
                Try this fruity salad for a light lunch or barbecue side. It's studded with jewel-like pomegranate seeds and is quick to prepare
              </p>

              <h4 className="setTitle">
                Ingredients
              </h4>
              
              <ul>
                <li>60ml olive oil</li>
                <li>2 tbsp lemon juice</li>
                <li>1 tbsp honey</li>
                <li>2 thyme sprigs, leaves picked</li>
                <li>100g baby lettuce leaves</li>
                <li>250g cherry tomatoes, halved</li>
                <li>3 spring onions, finely chopped</li>
                <li>1 red chilli, finely chopped (deseeded, if you prefer less heat)</li>
                <li>1 pomegranate, cut in half</li>
                <li>50g feta, crumbled</li>
              </ul>

              <h4 className="setTitle">
                Method
              </h4>
              <h4 className="setTitle">STEP 1</h4>
              <p>Combine the olive oil, lemon juice, honey and thyme leaves in a large bowl. Season well, then tip in the lettuce, tomatoes, spring onions and chilli, and toss to combine.</p>
              
              <h4 className="setTitle">
                Method
              </h4>
              <h4 className="setTitle">STEP 2</h4>
              <p>Scoop out the pomegranate seeds over a bowl and remove any white membrane. Arrange the dressed salad on a platter, then scatter over the pomegranate seeds and feta.</p>
           

            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseModal}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={isOpen1} onHide={handleCloseModal1} centered>
            <Modal.Header closeButton>
              <Modal.Title>Homemade grenadine</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <img src={cooking_pomegranate1} alt="Salad" className="img-fluid mb-3" />
              <p className="text-muted">
                Blend your own grenadine with pomegranate juice, sugar and orange blossom water to liven up lemonade and make your favourite cocktails and mocktails
              </p>

              <h4 className="setTitle">
                Ingredients
              </h4>
              
              <ul>
                <li>100ml pomegranate juice (not from concentrate)</li>
                <li>100g granulated sugar</li>
                <li>drop of orange blossom water</li>
                
              </ul>

              <h4 className="setTitle">
                Method
              </h4>
              <h4 className="setTitle">STEP 1</h4>
              <p>Put the pomegranate juice and sugar in a pan set over a low heat. Stir until the sugar has dissolved.</p>
              
              <h4 className="setTitle">
                Method
              </h4>
              <h4 className="setTitle">STEP 2</h4>
              <p>Remove from the heat. Leave to cool. Add the orange blossom water, then pour into a sterilised bottle. Will keep for two weeks.</p>
           

            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseModal1}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>



          <Modal show={isOpen2} onHide={handleCloseModal2} centered>
            <Modal.Header closeButton>
              <Modal.Title>Mulled pomegranate juice</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <img src={cooking_pomegranate3} alt="Salad" className="img-fluid mb-3" />
              <p className="text-muted">
                Look after all of your party guests, including the ones who aren't drinking with this simple, non-alcoholic Christmas-spiced fruit juice served warm
              </p>

              <h4 className="setTitle">
                Ingredients
              </h4>
              
              <ul>
                <li>1l pomegranate juice</li>
                <li>1 sprig rosemary</li>
                <li>1 tsp sumac</li>
                <li>1 cinnamon stick</li>
                <li>½ tsp coriander seeds</li>
                <li>½ orange, sliced</li>
                <li>¼ tsp orange blossom water</li>
                
              </ul>

              <h4 className="setTitle">
                Method
              </h4>
              <h4 className="setTitle">STEP 1</h4>
              <p>Pour the pomegranate juice into a large saucepan. Add the rosemary, sumac, cinnamon stick, coriander seeds, and orange. Bring to a simmer then pour through a sieve into a jug. Stir in the orange blossom water and pour into heatproof glasses or cups.</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseModal2}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>




    
        {/* new page dessign start */}
        <Container class="setTitle">
            <Row>

              <Col xs={12} sm={4}>
                <div>
                    <img src={pomegranate} alt="Logo" className="setPOMO setTitle TextAlign"/>
                </div>
              </Col>
              <Col xs={12} sm={8} className="setBackgroundcolor1">
                <Row className="text-center mt-3">
                  
                  <Col xs={4} sm={4}>
                  {my_fte_data && my_fte_data.ucs_id_fk ? (
                    <div className="gauge">
                      <div className="gauge__body">
                        <div className="gauge__fill gauge__fill-success"></div>
                        <div className="gauge__cover setFASValue">
                            {get_freshness_suger_age.freshness_percentage}%
                        </div>
                      </div>
                    </div>
                    ) : (
                      <p>No data available</p>
                    )}
                    <p className="mb-0 setPM fw-bold">Freshness</p>
                  </Col>

                  <Col xs={4} sm={4}>
                  {my_fte_data && my_fte_data.ucs_id_fk ? (
                    <div className="gauge">
                      <div className="gauge__body">
                        <div className="gauge__fill gauge__fill-warning"></div>
                        <div className="gauge__cover setFASValue">
                          {get_freshness_suger_age.fruits_age}
                          </div>
                      </div>
                    </div>
                    ) : (
                      <p>No data available</p>
                    )}
                    <p className="mb-0 setPM fw-bold">Age (days)</p>
                  </Col>
                  
                  <Col xs={4} sm={4}>
                  {my_fte_data && my_fte_data.ucs_id_fk ? (
                    <div className="gauge gauge">
                      <div className="gauge__body">
                        <div className="gauge__fill gauge__fill-info"></div>
                        <div className="gauge__cover setFASValue">
                          {get_freshness_suger_age.sugar_index}
                          </div>
                      </div>
                    </div>
                    ) : (
                      <p>No data available</p>
                    )}
                    <p className="mb-0 setPM fw-bold">Sugar Index</p>
                  </Col>
                  
                </Row>
              </Col>




              <Col xs={12} sm={12}>
                <div>
                  {my_fte_data && my_fte_data.ucs_id_fk ? (
                  <div>
                      <h4 class="setTitle setText">
                        {my_fte_data.ucs_id_fk
                        ? my_fte_data.ucs_id_fk.variety_id_fk.variety_name_en
                        : "Unknown Variety"} Pomegranate
                      </h4>
                       
                      <p class="text-muted text-justify setText1">
                      {my_fte_data.ucs_id_fk
                        ? my_fte_data.ucs_id_fk.variety_id_fk.variety_description_en
                        : "Unknown Description"}
                        
                      </p>
                    </div>
                    ) : (
                      <p>No data available</p>
                    )}
                </div>
              </Col>
              
              <Col xs={12} sm={4}>
              <div className="Farmer Information  p-4" 
              style={{
                backgroundImage: `url(${bg1})`, // Using the imported image
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                height: '100%', // Adjust the height as needed
                borderRadius: '15px',
              }}>
                    {my_fte_data && my_fte_data.ucs_id_fk ? (
                    <div className="">
                        <div>
                            <h4 className="setTitle">
                                Meet Your Grower :
                            </h4>
                            <p>Discover the Farmer and Origin Behind Your Pomegranate</p>
                            <div className="TextAlign">
                              <img 
                                  src={
                                    image_url+ my_fte_data?.ucs_id_fk?.user_id_fk?.user_image 
                                      ? image_url + my_fte_data.ucs_id_fk.user_id_fk.user_image 
                                      : "path_to_placeholder_image" // Replace with a valid placeholder image URL
                                  } 
                                  alt={my_fte_data?.ucs_id_fk?.user_id_fk?.user_name || "Unknown user name"} 
                                  className="img-fluid UserText " 
                                />
                            </div>
                            <label className="fw-bold setTitle setmp1">
                            <h4 className="setTitle" style={{"font-size": "14px"}}>BKS Farmer: </h4>

                              <span>
                                {my_fte_data.ucs_id_fk.user_id_fk
                                  ? my_fte_data.ucs_id_fk.user_id_fk.user_name
                                  : "Unknown user name"}
                              </span>
                              <hr class="custom-hr"/>
                            </label>
                            
                            
                            <h4 className="setTitle">
                                Farm Location :
                            </h4>
                            <p className="text-muted text-justify setText1 TextAlign setTitle">
                                {my_fte_data.ucs_id_fk.user_id_fk
                                      ? my_fte_data.ucs_id_fk.user_id_fk.user_address
                                      : "Unknown user address"}
                            </p>

                              <h4 className="setTitle">
                                Farm Specification :
                              </h4>
                              

                              <div>
                                  <div className="setUCSINFO">
                                    <div className="info-card d-flex justify-content-between align-items-center rounded">
                                    
                                    {my_fte_data && my_fte_data.ucs_id_fk ? (
                                      <div className="text-center me-3">
                                        <h6 className="text-warning unique-class" style={{'font-size':'12px'}}>
                                          {my_fte_data.ucs_id_fk
                                            ? my_fte_data.ucs_id_fk.area
                                            : "Unknown area"} Acre
                                          </h6>
                                        <p className="mb-0 setTitle">Total Area</p>
                                      </div>
                                      ) : (
                                        <p>No data available</p>
                                      )}

                                    {my_fte_data && my_fte_data.ucs_id_fk ? (
                                      <div className="text-center me-3">
                                        <h6 className="text-primary unique-class" style={{'font-size':'12px'}}>
                                          {my_fte_data.ucs_id_fk
                                            ? my_fte_data.ucs_id_fk.crop_plantingDate
                                            : "Sowing Date"} 
                                          </h6>
                                        <p className="mb-0 setTitle">Sowing Date</p>
                                      </div>
                                      ) : (
                                        <p>No data available</p>
                                      )}

                                    {my_fte_data && my_fte_data.ucs_id_fk ? ( 
                                      <div className="text-center me-3">
                                        <h6 className="unique-class SEtCOLOR" style={{'font-size':'12px'}}>
                                          {my_fte_data.ucs_id_fk
                                            ? my_fte_data.ucs_id_fk.expected_pruning_date
                                            : "Unknown Pruning Date"}
                                          </h6>
                                        <p className="mb-0 setTitle">Pruning Date</p>
                                      </div>
                                      ) : (
                                        <p>No data available</p>
                                      )}
                                    </div>
                                  </div>
                                </div>





                        </div>

                      </div>
                    ) : (
                      <p>No data available</p>
                    )}

                  <div className="p-4  mt-4">
                    <Row className="text-center mb-3 ">
                  
                      <Col xs={12} sm={4}>
                      {my_fte_data && my_fte_data.ucs_id_fk ? (
                        <div className="gauge">
                          <div className="gauge__body">
                            <div className="gauge__fill gauge__fill-success"></div>
                            <div className="gauge__cover">
                              {my_fte_data.ucs_id_fk
                                ? my_fte_data.ucs_id_fk.no_of_tree
                                : "Unknown Variety"}
                              </div>
                          </div>
                        </div>
                        ) : (
                          <p>No data available</p>
                        )}
                        <p className="mb-0 setTitle">No Of Trees</p>
                      </Col>
                      <Col xs={6} sm={4}>
                      {my_fte_data && my_fte_data.ucs_id_fk ? (
                        <div className="gauge">
                          <div className="gauge__body">
                            <div className="gauge__fill gauge__fill-warning"></div>
                            <div className="gauge__cover">
                              {my_fte_data.ucs_id_fk
                                ? my_fte_data.ucs_id_fk.plant_distance
                                : "Unknown Variety"}
                              </div>
                          </div>
                        </div>
                        ) : (
                          <p>No data available</p>
                        )}
                        <p className="mb-0 setTitle">Plant Distance(ft)</p>
                      </Col>
                      <Col xs={6} sm={4}>
                      {my_fte_data && my_fte_data.ucs_id_fk ? (
                        <div className="gauge ">
                          <div className="gauge__body">
                            <div className="gauge__fill gauge__fill-info"></div>
                            <div className="gauge__cover">
                              {my_fte_data.ucs_id_fk
                                ? my_fte_data.ucs_id_fk.row_distance
                                : "Unknown Variety"}
                              </div>
                          </div>
                        </div>
                        ) : (
                          <p>No data available</p>
                        )}
                        <p className="mb-0 setTitle">Row Distance(ft)</p>
                      </Col>
                      
                    </Row>     
                    
                  </div>
                 

                </div>





                
                


              </Col>



{/* 
              <Col xs={12} sm={8}>
                <div className="mt-2">
                  <h4 className="setTitle"> Detailed Insihts on Your Pomegranate </h4>
                </div>
                <div className="design-container">
                  <p className="static-text1">
                    <label className="label-normal1">{color_distribution_field_data[0]?.param_value_text}</label> <br />
                    <p className="setTExt">{color_distribution_field_data[0]?.param_id_fk?.param_title}</p>
                  </p>

                  <p className="static-text2">
                    <label className="label-normal2">{color_distribution_field_data[2]?.param_value_text}</label> <br />
                    <p className="setTExt">{color_distribution_field_data[2]?.param_id_fk?.param_title}</p>
                  </p>

                  <p className="static-text3">
                    <label className="label-normal3">{color_distribution_field_data[1]?.param_value_text}</label> <br />
                    <p className="setTExt">{color_distribution_field_data[1]?.param_id_fk?.param_title}</p>
                  </p>

                  <p className="static-text6">
                    <label className="label-normal6">{color_distribution_field_data[3]?.param_value_text}</label> <br />
                    <p className="setTExt">{color_distribution_field_data[3]?.param_id_fk?.param_title}</p>
                  </p>


                  <p className="static-text4">
                    <label className="label-normal4">{color_distribution_field_data[5]?.param_value_text}</label> <br />
                    <p className="setTExt">{color_distribution_field_data[5]?.param_id_fk?.param_title}</p>
                  </p>

                  <p className="static-text5">
                    <label className="label-normal5">{color_distribution_field_data[4]?.param_value_text}</label> <br />
                    <p className="setTExt">{color_distribution_field_data[4]?.param_id_fk?.param_title}</p>
                  </p>
                 

                 <img src={pomegranate_color} alt="Logo" className="img-fluid SetIMG1" />
                </div>
              </Col> */}

          <Col xs={12} sm={8}>
            <div className="mt-2">
              <h4 className="setTitle"> The Route to Your Plate: How Your Fruit Traveled </h4>
            </div>

            <div className="stepper-container" style={{
              backgroundImage: `url(${bg2})`, // Using the imported image
              backgroundSize: 'cover',
              backgroundPosition: 'right',
              backgroundRepeat: 'no-repeat',
              height: '100vh',// Adjust the height as needed
              borderRadius: '15px', // Correct camelCase syntax for border radius
              overflow: 'hidden', // Ensure content doesn't overflow outside the border radius
            }}>
            {/* Stepper */}
            <div className="stepper-vertical"  
            >
              {steps.map((step, index) => (
                <div key={index} className="step-container-vertical">
                  <div
                    className={`circle ${index <= activeStep ? 'active' : ''}`}
                  >
                    {index + 1}
                  </div>
                  <div className="label">
                    <strong>{step.name}</strong>
                    <br />
                    <small>{`Date: ${step.date}`}</small>
                    <br />
                    <small>{`Time: ${step.time}`}</small>
                  </div>
                  {index < steps.length - 1 && (
                    <div
                      className={`line-vertical ${index < activeStep ? 'active' : ''}`}
                    ></div>
                  )}
                </div>
              ))}
            </div>

           
          </div>
           {/* Content */}
           <div className="content">
              <p>{`At ${steps[activeStep].name}`}</p>
              <div className="buttons">
                <button onClick={handleBack} disabled={activeStep === 0} className="button">
                  Back
                </button>
                {activeStep === steps.length - 1 ? (
                  <button onClick={handleReset} className="button">
                    Reset
                  </button>
                ) : (
                  <button onClick={handleNext} className="button">
                    Next
                  </button>
                )}
              </div>
            </div>
    
  </Col>


              <Col xs={12} sm={8}>
                

                <div className="mt-2">
                  <h4 className="setTitle"> Detailed Insihts on Your Pomegranate </h4>
                </div>
                <div className="row TextAlign">
                    <div className="col-md-4 col-4 mt-2">
                    <p className="">
                      <label className="label-normal2">{color_distribution_field_data[0]?.param_value_text}</label> <br />
                      <p className="setTExt">{color_distribution_field_data[0]?.param_id_fk?.param_title}</p>
                    </p>

                    </div>
                    <div className="col-md-4 col-4 mt-2">
                      <p className="">
                        <label className="label-normal2">{color_distribution_field_data[1]?.param_value_text}</label> <br />
                        <p className="setTExt">{color_distribution_field_data[1]?.param_id_fk?.param_title}</p>
                      </p>
                    </div>
                    <div className="col-md-4 col-4 mt-2">
                      <p className="">
                        <label className="label-normal1">{color_distribution_field_data[2]?.param_value_text}</label> <br />
                        <p className="setTExt">{color_distribution_field_data[2]?.param_id_fk?.param_title}</p>
                      </p>
                    </div>
                </div>

                <div className="row TextAlign">
                    <div className="col-md-12 col-12">
                        <img src={pomegranate_color} alt="Logo" className="img-fluid" />
                    </div>
                </div>


                <div className="row TextAlign">
                    <div className="col-md-4 col-4 mt-2">
                      <p className="">
                        <label className="label-normal2">{color_distribution_field_data[3]?.param_value_text}</label> <br />
                        <p className="setTExt">{color_distribution_field_data[3]?.param_id_fk?.param_title}</p>
                      </p>
                    </div>
                    <div className="col-md-4 col-4 mt-2">
                      <p className="">
                        <label className="label-normal4">{color_distribution_field_data[4]?.param_value_text}</label> <br />
                        <p className="setTExt">{color_distribution_field_data[4]?.param_id_fk?.param_title}</p>
                      </p>
                    </div>
                    <div className="col-md-4 col-4 mt-2">
                      <p className="">
                        <label className="label-normal4">{color_distribution_field_data[5]?.param_value_numeric}</label> <br />
                        <p className="setTExt">{color_distribution_field_data[5]?.param_id_fk?.param_title}</p>
                      </p>
                    </div>
                </div>


                 {/* communication */}
                 {/* <div className="hide-on-">
                    <h4 className="setTitle">User Communication and Alert Summary</h4>
                    <p className="text-muted text-justify setText1">
                      Track the number of calls and alerts sent to each user for effective communication management.
                    </p>

                    <div className="container mt-2">
                      <div className="row">
                       
                        <div className="col-md-6 col-6">
                          <div className="card text-center mb-3" style={{ backgroundColor: '#FFF5E7', borderRadius: '15px' }}>
                            <div className="card-body">
                            <img src={phone_call} alt="Logo" className=" setIMG" /> 
                              <h5 className="card-title mt-3">{alert_count.call_count}</h5>
                              <p className="card-text TextSET">Audio & Video Calls</p>
                            </div>
                          </div>
                        </div>

                       
                        <div className="col-md-6 col-6">
                          <div className="card text-center mb-3" style={{ backgroundColor: '#E0F7E8', borderRadius: '15px' , height: '184px' }}>
                            <div className="card-body">
                            <img src={clouds} alt="Logo" className=" setIMG" />
                              <h5 className="card-title mt-3 setNUmber">{alert_count.weather_alert_count}</h5>
                              <p className="card-text TextSET">Weather Forecast Alerts</p>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6 mt-2 col-6">
                          <div className="card text-center mb-3" style={{ backgroundColor: '#FDF2E9', borderRadius: '15px' }}>
                            <div className="card-body">
                              <img src={pest} alt="Logo" className=" setIMG" />
                              <h5 className="card-title mt-3 setNUmber">{alert_count.ucs_disease_pest_count}</h5>
                              <p className="card-text TextSET">Disease & Pest Alerts</p>
                            </div>
                          </div>
                        </div>

                      
                        <div className="col-md-6 mt-2 col-6">
                          <div className="card text-center mb-3" style={{ backgroundColor: '#EAF2F8', borderRadius: '15px', height: '184px' }}>
                            <div className="card-body">
                              <img src={drop} alt="Logo" className=" setIMG" />
                              <h5 className="card-title mt-3 setNUmber">{alert_count.ucs_irrigation_count}</h5>
                              <p className="card-text TextSET">Irrigation Alerts</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div> */}


                {/* <h5 class="fw-bold mt-5 setTitle">Advisory And Communication History</h5>
                <p class="text-muted">Review the complete history of the advisor and communication with the farmer.</p>
                
                <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                  <Table striped bordered hover>
                    <thead>
                  
                      <tr>
                        <th>Sr. No</th>
                        <th>Date Connected</th>
                        <th>Advisor</th>
                        <th>Status</th>
                        <th>BKS Advisory</th>
                      </tr>

                    </thead>
                    <tbody>
                    {get_Connect_Comment_History && get_Connect_Comment_History.length > 0 ? (
                              get_Connect_Comment_History.map((Connect_Comment_History, index) => (
                      <tr>
                        <td>{index+1}</td>
                        <td>{Connect_Comment_History.created_at}</td>
                        <td>
                                vivek tayde
                          </td>
                        <td>{Connect_Comment_History.call_status}</td>
                        <td>
                          {Connect_Comment_History.call_status === 'not_connected' ? (
                            Connect_Comment_History.not_connected_reason
                          ) : (

                            <>
                            <p>
                              Call Purpose:
                              {Connect_Comment_History.has_advisory === 1 && ' Advisory'}
                              {Connect_Comment_History.has_sales === 1 && ' Sales'}
                              {Connect_Comment_History.has_onboarding === 1 && ' Onboarding'}
                              {Connect_Comment_History.has_app_obstacles === 1 && ' App Obstacles'}
                              {Connect_Comment_History.has_welcome_call === 1 && ' Welcome Call'}
                              {Connect_Comment_History.has_follow_up === 1 && ' Follow Up'}
                              {Connect_Comment_History.has_history === 1 && ' History'}
                            </p>
                            {Connect_Comment_History.adv_note}
                          </>

                          )}
                        </td>
                      </tr>
                        ))
                        ) : (
                      <p>No data available</p>
                      )}
                    
                    </tbody>
                  </Table>
                </div> */}


                {/* <h5 class="fw-bold setTitle">Customized Crop Calender</h5>
                <p class="text-muted">Explore the personalized calendar tailored for the farmer, detailing the entire crop life cycle.</p>
                <div className="card TextAlign">
                  <img src={image_url+get_ucs_calender.activity_image} alt="Logo" className=" " /> 
                  <div>
                    <h5 class="fw-bold setTitle">Activity</h5>
                    <p>{get_ucs_calender.activity_title}</p>
                  </div>
                  <div>
                    <h5 class="fw-bold setTitle">Description</h5>
                    <p>{get_ucs_calender.activity_desc}</p>
                  </div>
                  <div>
                    <h5 class="fw-bold setTitle">Period</h5>
                    <p>Start Date : {get_ucs_calender.start_date} End Date : {get_ucs_calender.end_date}</p>
                  </div>
                  
                  

                </div> */}





                {/* product uses */}
                {/* <h5 class="fw-bold setTitle">View the product utilized throughout the crop life cycle on the farm.</h5>
                 <div className="container mt-2">
                    <div className="row">
                     
                      {get_product_details && get_product_details.length > 0 ? (
                          get_product_details.map((product_details, index) => (
                      <div className="col-md-6 col-6">
                        <div className="card text-center mb-3" style={{ backgroundColor: '#fff', borderRadius: '15px', height: '267px' }}>
                          <div className="card-body">
                          
                            <img src={image_url2+product_details.image} alt="Logo" className=" " /> 
                            <p className="">Name : {product_details.name}</p>
                            <p className="">total used : {product_details.is_calculatable} {product_details.unit_name}</p>
                            
                            
                          </div>
                        </div>
                      </div>
                        ))
                        ) : (
                        <p>No data available</p>
                        )}

                    

                    </div>
                  </div> */}
                  {/* <h5 class="fw-bold setTitle">Fruit Distribution : </h5>
                  <div className="card">
                  <div className="ms-2 w-100">
                      <div className="d-flex justify-content-between">
                        <h6 className="card-title setTEXT2">Organic</h6>
                        <h6 className="card-title progress-value setTEXT3">Chemical</h6> 
                      </div>
                      <div className="progress mt-2">
                        <div
                          className="progress-bar bg-success" 
                          role="progressbar"
                          style={{ width: '75%' }} 
                          aria-valuenow="75" 
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                      <h5 className=" mt-3 fw-bold text-success TextAlign">60 %</h5> 
                    </div>
                  </div> */}



                {/* <button class="btn color-detail colorSet mt-4">Distribution Detail</button>
                <div className="p-4">
                  <Row className="text-center mb-3">
                  {distribution_field_data && distribution_field_data.length > 0 ? (
                      distribution_field_data.map((field, index) => (
                        <Col xs={6} sm={4} key={index} className="mt-2">
                          <div className="gauge">
                            <div className="gauge__body">
                             
                              {['float', 'number'].includes(field.param_id_fk.param_type) ? (
                                <div
                                  className={`gauge__fill ${getGaugeColorClass(field.param_value_numeric)}`}
                                  style={{
                                    transform: `rotate(${(field.param_value_numeric / 100) * 0.6}turn)`,
                                  }}
                                ></div>
                              ) : null}

                              <div className="gauge__cover">
                                {field.param_id_fk.param_type === 'text' || field.param_id_fk.param_type === 'textarea' ? (
                                  <span>{field.param_value_text}%</span>
                                ) : field.param_id_fk.param_type === 'float' || field.param_id_fk.param_type === 'number' ? (
                                  <span>{field.param_value_numeric}%</span>
                                ) : field.param_id_fk.param_type === 'file' && field.param_value_file ? (
                                  <a
                                    href={field.param_value_file.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="btn btn-primary"
                                  >
                                    View File
                                  </a>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <p className="mb-0">{field.param_id_fk.param_title}</p>
                        </Col>
                    ))
                    ) : (
                    <p>No data available</p>
                  )}

                  </Row>     
                  
                </div> */}


                {/* <button class="btn color-detail colorSet">Expected Grade Distribution</button>

                <div className="p-4">
                  <div className="container mt-2">
                      <div className="row">
                        {graded_yields_data && graded_yields_data.length > 0 ? (
                            graded_yields_data.map((graded_yields, index) => (
                            <div className="col-md-6 mt-2">
                                <div className="card pomegranate-card">
                                  <div className="card-body">
                                    <div className="d-flex align-items-center">
                                      <img src={image_url1 + graded_yields.image} alt="Pomegranate" className="pomegranate-img" />

                                      <div className="ms-2 w-100">
                                        <div className="d-flex justify-content-between">
                                          <h6 className="card-title">{graded_yields.grade}</h6>
                                          <span className="progress-value text-success">{graded_yields.percentage}%</span>
                                        </div>
                                        <div className="progress mt-2">
                                          <div
                                            className={`progress-bar ${getColorClass(graded_yields.percentage)}`}
                                            role="progressbar"
                                            style={{ width: `${graded_yields.percentage}%` }}
                                            aria-valuenow={graded_yields.percentage}
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                          ></div>
                                        </div>
                                        <p className="projected-yield text-muted mt-2">Projected Yield</p>
                                        <h5 className="fw-bold text-danger">{graded_yields.yield}.0MT</h5>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                            </div>
                          ))
                          ) : (
                          <p>No data available</p>
                        )}
                    </div>
                </div>
                </div> */}

                 {/* section 6 */}
                 {/* <button class="btn color-detail colorSet">Disease Details</button>
                <div className="p-4">
                  <div className="container mt-2">
                      <div className="row">
                      
                      {diseases_fields_data && diseases_fields_data.length > 0 ? (
                            diseases_fields_data.map((diseases_fields, index) => (
                            <div className="col-md-4 col-6 mt-2">
                                <div className="card">
                                  <div className="card-body text-center">
                                    <h6 class="fw-bold text-success">{diseases_fields.param_value_numeric} %</h6>
                                    <p class="mb-0">{diseases_fields.param_id_fk.param_title}</p>
                                  </div>
                              </div>
                          </div>
                          ))
                          ) : (
                          <p>No data available</p>
                        )}

                    

                      </div>
                  </div>
                </div> */}



                {/* <h5 class="fw-bold mt-5 setTitle">Crop Cycle Alerts</h5>
                <p class="text-muted">Track All Alert Sent to the farmer throughtout the crop cycle .</p>
                <div>
                    
                      <div className="container mt-2">
                            <div className="row">
                              <div className="col-md-4 mt-2">
                              {get_ucs_alert_Data ? (
                                  <div className="card">
                                    <div className="card-body text-center">
                                      <h6 class="text-danger fw-bold">{get_ucs_alert_Data.alert_title}</h6>
                                      <p class="text-muted small">{get_ucs_alert_Data.alert_description}</p>
                                      <p class="text-muted small">{get_ucs_alert_Data.created_at}</p>
                                      <img src={image_url2+get_ucs_alert_Data.alert_image} alt="Logo" className="img-fluid"/>
                                    </div>
                                  </div>
                                  ) : (
                                    <p className="text-center text-muted">No data available</p>
                                  )}
                              </div>

                              <div className="col-md-4 mt-2">
                                {get_ucs_alerts_danger ? (
                                    <div className="card">
                                      <div className="card-body text-center">
                                        <h6 class="text-danger fw-bold">{get_ucs_alerts_danger.alert_title}</h6>
                                        <p class="text-muted small">{get_ucs_alerts_danger.alert_description}</p>
                                        <p class="text-muted small">{get_ucs_alerts_danger.created_at}</p>
                                        <img src={image_url2+get_ucs_alerts_danger.alert_image} alt="Logo" className="img-fluid"/>
                                      </div>
                                    </div>
                                    ) : (
                                      <p className="text-center text-muted">No data available</p>
                                    )}
                              </div>

                              <div className="col-md-4 mt-2">
                                {get_ucs_irrigation && (get_ucs_irrigation.alert_title || get_ucs_irrigation.alert_desc || get_ucs_irrigation.created_at) ? (
                                  <div className="card">
                                    <div className="card-body text-center">
                                      <h6 className="text-danger fw-bold">
                                        {get_ucs_irrigation.alert_title_en || "No title available"}
                                      </h6>
                                      <p className="text-muted small">
                                        {get_ucs_irrigation.alert_desc_en || "No description available"}
                                      </p>
                                      <p className="text-muted small">
                                        {get_ucs_irrigation.created_at ? new Date(get_ucs_irrigation.created_at).toLocaleDateString() : "No date available"}
                                      </p>
                                    </div>
                                  </div>
                                ) : (
                                  <p className="text-center text-muted">No data available</p>
                                )}
                              </div>



                            
                          </div>
                    </div>
                  

                </div> */}

                
              </Col>


              

              
              <Col xs={12} sm={8} className="">
                    <div>
                      <h5 class="fw-bold setTitle">Pomegranate Nutrition Information</h5>
                      <p class="text-muted">Learn about the nutritional benefits and values of pomegranates.</p>
                    </div>
                    <div className="hide-on-desktop">
                      <div className="container mt-2">
                          <div className="row">
                              <div className="col-md-4 col-6 mt-2">
                                    <div className="card">
                                      <div className="card-body text-center">
                                        <p class="mb-0 setTitle">Calories</p>
                                        <div className="progress mt-2">
                                          <div
                                            className="progress-bar bg-success"
                                            role="progressbar"
                                            style={{ width: '83.33%' }}
                                            aria-valuenow="83.33"
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                            >
                                          </div>
                                        </div>
                                        <h6 class="fw-bold text-success mt-2">{get_crop_nutrition.calories_kcal} kcal</h6>
                                      </div>
                                  </div>
                              </div>
                              
                              <div className="col-md-4 col-6 mt-2">
                                    <div className="card">
                                      <div className="card-body text-center">
                                        <p class="mb-0 setTitle">Protein</p>
                                        <div className="progress mt-2">
                                          <div
                                            className="progress-bar bg-info"
                                            role="progressbar"
                                            style={{ width: '83.33%' }}
                                            aria-valuenow="83.33"
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                            >
                                          </div>
                                        </div>
                                        <h6 class="fw-bold text-info mt-2">{get_crop_nutrition.protein_gram} grams</h6>
                                      </div>
                                  </div>
                              </div>

                              <div className="col-md-4 col-6 mt-2">
                                    <div className="card">
                                      <div className="card-body text-center">
                                        <p class="mb-0 setTitle">Fat</p>
                                        <div className="progress mt-2">
                                          <div
                                            className="progress-bar bg-warning"
                                            role="progressbar"
                                            style={{ width: '83.33%' }}
                                            aria-valuenow="83.33"
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                            >
                                          </div>
                                        </div>
                                        <h6 class="fw-bold text-warning mt-2">{get_crop_nutrition.fat_grams} grams</h6>
                                      </div>
                                  </div>
                              </div>

                              <div className="col-md-4 col-6 mt-2">
                                    <div className="card">
                                      <div className="card-body text-center">
                                        <p class="mb-0 setTitle">Sugars</p>
                                        <div className="progress mt-2">
                                          <div
                                            className="progress-bar bg-info"
                                            role="progressbar"
                                            style={{ width: '83.33%' }}
                                            aria-valuenow="83.33"
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                            >
                                          </div>
                                        </div>
                                        <h6 class="fw-bold text-info mt-2">{get_crop_nutrition.sugars_grams} kcal</h6>
                                      </div>
                                  </div>
                              </div>

                              <div className="col-md-4 col-6 mt-2">
                                    <div className="card">
                                      <div className="card-body text-center">
                                        <p class="mb-0 setTitle">Calcium</p>
                                        <div className="progress mt-2">
                                          <div
                                            className="progress-bar bg-success"
                                            role="progressbar"
                                            style={{ width: '83.33%' }}
                                            aria-valuenow="83.33"
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                            >
                                          </div>
                                        </div>
                                        <h6 class="fw-bold text-success mt-2">{get_crop_nutrition.calcium_mg} kcal</h6>
                                      </div>
                                  </div>
                              </div>

                              <div className="col-md-4 col-6 mt-2">
                                    <div className="card">
                                      <div className="card-body text-center">
                                        <p class="mb-0 setTitle">Phosphorus</p>
                                        <div className="progress mt-2">
                                          <div
                                            className="progress-bar bg-danger"
                                            role="progressbar"
                                            style={{ width: '83.33%' }}
                                            aria-valuenow="83.33"
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                            >
                                          </div>
                                        </div>
                                        <h6 class="fw-bold text-danger mt-2">{get_crop_nutrition.phosphorus_mg} kcal</h6>
                                      </div>
                                  </div>
                              </div>

                              
                          </div>
                          <div className="col-md-12 col-12 mt-2">
                            <img src={pomegranate_nutriance} alt="Logo" className="img-fluid"/>  
                          </div>

                          <div className="row">
                              <div className="col-md-4 col-6 mt-2">
                                    <div className="card">
                                      <div className="card-body text-center">
                                        <p class="mb-0 setTitle">Carbohydrates</p>
                                        <div className="progress mt-2">
                                          <div
                                            className="progress-bar bg-danger"
                                            role="progressbar"
                                            style={{ width: '83.33%' }}
                                            aria-valuenow="83.33"
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                            >
                                          </div>
                                        </div>
                                        <h6 class="fw-bold text-danger mt-2">{get_crop_nutrition.carbohydrates_grams} kcal</h6>
                                      </div>
                                  </div>
                              </div>
                              
                              <div className="col-md-4 col-6 mt-2">
                                    <div className="card">
                                      <div className="card-body text-center">
                                        <p class="mb-0 setTitle">Dietary Fiber</p>
                                        <div className="progress mt-2">
                                          <div
                                            className="progress-bar bg-warning"
                                            role="progressbar"
                                            style={{ width: '83.33%' }}
                                            aria-valuenow="83.33"
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                            >
                                          </div>
                                        </div>
                                        <h6 class="fw-bold text-warning mt-2">{get_crop_nutrition.dietary_fiber_grams} kcal</h6>
                                      </div>
                                  </div>
                              </div>

                              <div className="col-md-4 col-6 mt-2">
                                    <div className="card">
                                      <div className="card-body text-center">
                                        <p class="mb-0 setTitle">Potassium</p>
                                        <div className="progress mt-2">
                                          <div
                                            className="progress-bar bg-info"
                                            role="progressbar"
                                            style={{ width: '83.33%' }}
                                            aria-valuenow="83.33"
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                            >
                                          </div>
                                        </div>
                                        <h6 class="fw-bold text-info mt-2">{get_crop_nutrition.potassium_mg} kcal</h6>
                                      </div>
                                  </div>
                              </div>

                              <div className="col-md-4 col-6 mt-2">
                                    <div className="card">
                                      <div className="card-body text-center">
                                        <p class="mb-0 setTitle">Vitamic C</p>
                                        <div className="progress mt-2">
                                          <div
                                            className="progress-bar bg-success"
                                            role="progressbar"
                                            style={{ width: '83.33%' }}
                                            aria-valuenow="83.33"
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                            >
                                          </div>
                                        </div>
                                        <h6 class="fw-bold text-success mt-2">{get_crop_nutrition.vitamin_c_mg} kcal</h6>
                                      </div>
                                  </div>
                              </div>

                              <div className="col-md-4 col-6 mt-2">
                                    <div className="card">
                                      <div className="card-body text-center">
                                        <p class="mb-0 setTitle">Vitamic K</p>
                                        <div className="progress mt-2">
                                          <div
                                            className="progress-bar bg-info"
                                            role="progressbar"
                                            style={{ width: '83.33%' }}
                                            aria-valuenow="83.33"
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                            >
                                          </div>
                                        </div>
                                        <h6 class="fw-bold text-info mt-2">{get_crop_nutrition.vitamin_k_mg} grams</h6>
                                      </div>
                                  </div>
                              </div>

                              <div className="col-md-4 col-6 mt-2">
                                    <div className="card">
                                      <div className="card-body text-center">
                                        <p class="mb-0 setTitle">Megnesium</p>
                                        <div className="progress mt-2">
                                          <div
                                            className="progress-bar bg-warning"
                                            role="progressbar"
                                            style={{ width: '83.33%' }}
                                            aria-valuenow="83.33"
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                            >
                                          </div>
                                        </div>
                                        <h6 class="fw-bold text-warning mt-2">{get_crop_nutrition.megnesium_mg} grams</h6>
                                      </div>
                                  </div>
                              </div>
                          </div>

                      </div>
                    </div>

                    <div className="hide-on-mobile">
                        <div className="container mt-2">
                            <div className="row">
                                <div className="col-md-4">
                                      <div className="card">
                                        <div className="card-body text-center">
                                          <p class="mb-0 setTitle">Calories</p>
                                          <div className="progress mt-2">
                                            <div
                                              className="progress-bar bg-success"
                                              role="progressbar"
                                              style={{ width: '83.33%' }}
                                              aria-valuenow="83.33"
                                              aria-valuemin="0"
                                              aria-valuemax="100"
                                              >
                                            </div>
                                          </div>
                                          <h6 class="fw-bold text-success mt-2">{get_crop_nutrition.calories_kcal} kcal</h6>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                      <div className="card">
                                        <div className="card-body text-center">
                                          <p class="mb-0 setTitle">Protein</p>
                                          <div className="progress mt-2">
                                            <div
                                              className="progress-bar bg-info"
                                              role="progressbar"
                                              style={{ width: '83.33%' }}
                                              aria-valuenow="83.33"
                                              aria-valuemin="0"
                                              aria-valuemax="100"
                                              >
                                            </div>
                                          </div>
                                          <h6 class="fw-bold text-info mt-2">{get_crop_nutrition.protein_gram} grams</h6>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                      <div className="card">
                                        <div className="card-body text-center">
                                          <p class="mb-0 setTitle">Fat</p>
                                          <div className="progress mt-2">
                                            <div
                                              className="progress-bar bg-warning"
                                              role="progressbar"
                                              style={{ width: '83.33%' }}
                                              aria-valuenow="83.33"
                                              aria-valuemin="0"
                                              aria-valuemax="100"
                                              >
                                            </div>
                                          </div>
                                          <h6 class="fw-bold text-warning mt-2">{get_crop_nutrition.fat_grams} grams</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="setCARD">
                          <div className="card">
                                            <div className="card-body text-center">
                                              <p class="mb-0 setTitle">Sugars</p>
                                              <div className="progress mt-2">
                                                <div
                                                  className="progress-bar bg-info"
                                                  role="progressbar"
                                                  style={{ width: '83.33%' }}
                                                  aria-valuenow="83.33"
                                                  aria-valuemin="0"
                                                  aria-valuemax="100"
                                                  >
                                                </div>
                                              </div>
                                              <h6 class="fw-bold text-info mt-2">{get_crop_nutrition.sugars_grams} kcal</h6>
                                            </div>
                            </div>
                        </div>
                        <div className="setCARD1">
                          <div className="card">
                                            <div className="card-body text-center">
                                              <p class="mb-0 setTitle">Calcium</p>
                                              <div className="progress mt-2">
                                                <div
                                                  className="progress-bar bg-success"
                                                  role="progressbar"
                                                  style={{ width: '83.33%' }}
                                                  aria-valuenow="83.33"
                                                  aria-valuemin="0"
                                                  aria-valuemax="100"
                                                  >
                                                </div>
                                              </div>
                                              <h6 class="fw-bold text-success mt-2">{get_crop_nutrition.calcium_mg} kcal</h6>
                                            </div>
                            </div>
                        </div>
                        <div className="setCARD2">
                          <div className="card">
                                            <div className="card-body text-center">
                                              <p class="mb-0 setTitle">Phosphorus</p>
                                              <div className="progress mt-2">
                                                <div
                                                  className="progress-bar bg-danger"
                                                  role="progressbar"
                                                  style={{ width: '83.33%' }}
                                                  aria-valuenow="83.33"
                                                  aria-valuemin="0"
                                                  aria-valuemax="100"
                                                  >
                                                </div>
                                              </div>
                                              <h6 class="fw-bold text-danger mt-2">{get_crop_nutrition.phosphorus_mg} kcal</h6>
                                            </div>
                            </div>
                        </div>
                        <div className="setCARD3">
                          <div className="card">
                                            <div className="card-body text-center">
                                              <p class="mb-0 setTitle">Carbohydrates</p>
                                              <div className="progress mt-2">
                                                <div
                                                  className="progress-bar bg-danger"
                                                  role="progressbar"
                                                  style={{ width: '83.33%' }}
                                                  aria-valuenow="83.33"
                                                  aria-valuemin="0"
                                                  aria-valuemax="100"
                                                  >
                                                </div>
                                              </div>
                                              <h6 class="fw-bold text-danger mt-2">{get_crop_nutrition.carbohydrates_grams} kcal</h6>
                                            </div>
                            </div>
                        </div>

                        <div className="setCARD4">
                          <div className="card">
                                            <div className="card-body text-center">
                                              <p class="mb-0 setTitle">Dietary Fiber</p>
                                              <div className="progress mt-2">
                                                <div
                                                  className="progress-bar bg-warning"
                                                  role="progressbar"
                                                  style={{ width: '83.33%' }}
                                                  aria-valuenow="83.33"
                                                  aria-valuemin="0"
                                                  aria-valuemax="100"
                                                  >
                                                </div>
                                              </div>
                                              <h6 class="fw-bold text-warning mt-2">{get_crop_nutrition.dietary_fiber_grams} kcal</h6>
                                            </div>
                            </div>
                        </div>
                        <div className="setCARD5">
                          <div className="card">
                                            <div className="card-body text-center">
                                              <p class="mb-0 setTitle">Potassium</p>
                                              <div className="progress mt-2">
                                                <div
                                                  className="progress-bar bg-success"
                                                  role="progressbar"
                                                  style={{ width: '83.33%' }}
                                                  aria-valuenow="83.33"
                                                  aria-valuemin="0"
                                                  aria-valuemax="100"
                                                  >
                                                </div>
                                              </div>
                                              <h6 class="fw-bold text-success mt-2">{get_crop_nutrition.potassium_mg} kcal</h6>
                                            </div>
                            </div>
                        </div>
                            <img src={pomegranate_nutriance} alt="Logo" className="img-fluid pomegranate_nutriance"/>  
                          <div className="row setCARD12">
                            <div className="col-md-4">
                                  <div className="card">
                                    <div className="card-body text-center">
                                      <p class="mb-0 setTitle">Vitamic C</p>
                                      <div className="progress mt-2">
                                        <div
                                          className="progress-bar bg-success"
                                          role="progressbar"
                                          style={{ width: '83.33%' }}
                                          aria-valuenow="83.33"
                                          aria-valuemin="0"
                                          aria-valuemax="100"
                                          >
                                        </div>
                                      </div>
                                      <h6 class="fw-bold text-success mt-2">{get_crop_nutrition.vitamin_c_mg} kcal</h6>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4">
                                  <div className="card">
                                    <div className="card-body text-center">
                                      <p class="mb-0 setTitle">Vitamic K</p>
                                      <div className="progress mt-2">
                                        <div
                                          className="progress-bar bg-info"
                                          role="progressbar"
                                          style={{ width: '83.33%' }}
                                          aria-valuenow="83.33"
                                          aria-valuemin="0"
                                          aria-valuemax="100"
                                          >
                                        </div>
                                      </div>
                                      <h6 class="fw-bold text-info mt-2">{get_crop_nutrition.vitamin_k_mg} grams</h6>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4">
                                  <div className="card">
                                    <div className="card-body text-center">
                                      <p class="mb-0 setTitle">Megnesium</p>
                                      <div className="progress mt-2">
                                        <div
                                          className="progress-bar bg-warning"
                                          role="progressbar"
                                          style={{ width: '83.33%' }}
                                          aria-valuenow="83.33"
                                          aria-valuemin="0"
                                          aria-valuemax="100"
                                          >
                                        </div>
                                      </div>
                                      <h6 class="fw-bold text-warning mt-2">{get_crop_nutrition.megnesium_mg} grams</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <h5 class="fw-bold setTitle">Health Benefits of Pomegranate</h5>
                    <p class="text-muted">Discover the various health benefits of consuming pomegranates.</p>
                    <div style={{
                              backgroundImage: `url(${pomegranate_background})`,
                              backgroundSize: 'cover',
                              backgroundPosition: 'center',
                              padding: '20px', // Optional padding
                              borderRadius: '10px', // Optional border radius
                            }}>

                            {get_health_benefits && get_health_benefits.length > 0 ? (
                              get_health_benefits.map((health_benefits, index) => (
                            
                              <div>
                                <h5 class="fw-bold setTitle"><label className="setHNB">{index + 1}</label> {health_benefits.health_benefits} </h5>
                                <p class="text-muted setMArgin setMP">{health_benefits.health_benefits_description}</p>
                              </div>

                            ))
                            ) : (
                          <p>No data available</p>
                          )}

                    </div>

                   

                    {/* video tour */}
                    <h5 class="fw-bold setTitle">Farm Virtual Tour and Activities</h5>
                    <p class="text-muted">Experience a virtual tour of the farm and observe various farmer activities in action.</p>
                    <div className="row">
                        {ucs_video_tour.map((videoTour, index) => (
                          <div className="col-md-6 col-12">
                            <div className="video-container" style={{ position: "relative", width: "95%", margin: "auto" }}>
                              <div>
                                <div className="VideoTitle">
                                  <p id="videoTitle2" className="text-white video-title">
                                    {ucs_video_tour[currentVideoIndex]?.no_of_days || 'N/A'}
                                  </p>
                                  <p id="videoDate2" className="text-white">
                                    {ucs_video_tour[currentVideoIndex]?.activity_perform_date || 'N/A'}
                                  </p>
                                </div>
                              </div>

                        {/* Play/Pause button */}
                        <img
                          src={isPlaying1 ? pause_button : play_button}
                          alt={isPlaying1 ? 'Pause Button' : 'Play Button'}
                          className="SetplayButton"
                          style={{
                            position: "absolute",
                            top: "32%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            cursor: "pointer",
                            zIndex: 1,
                          }}
                          onClick={handlePlayButtonClick1}
                        />

                        {/* Video element */}
                        
                          <video
                            key={index}
                            id="videoPlayer1"
                            ref={videoRef1}
                            style={{ width: "100%", height: "auto", borderRadius: "10px" }}
                            controls={false}
                            onEnded={handleVideoEnd}
                            onError={(e) => console.error('Failed to load video', e)}
                          >
                            
                            Your browser does not support the video tag.
                          </video>
                      
                          </div>
                          </div>
                            ))}
                      </div>

                   




                    

                    {/* farm images */}

                    <h5 class="fw-bold setTitle">Audit Photos and Videos</h5>
                    <p class="text-muted">Browse photos and videos submitted during the physical audit of the farm.</p>
                    {/* section 8 */}
                    <div className="">
                    
                   
                        {/* Render media thumbnails */}
                        <div className="row">
                          {get_fte_media.map((fte_media, index) => (
                            <div key={index} className="col-md-3 col-6">
                              <div onClick={() => openModal(index)}>
                                {fte_media.media_type === "image" ? (
                                  <img
                                    src={image_url2 + fte_media.media_file}
                                    alt="Thumbnail"
                                    className="img-fluid"
                                    style={{ width: "100%", height: "120px" }}
                                  />
                                ) : fte_media.media_type === "video" ? (
                                  <video width="100%" height="120px" muted>
                                    <source src={image_url2 + fte_media.media_file} type="video/mp4" />
                                  </video>
                                ) : (
                                  <p>Unsupported media type</p>
                                )}
                              </div>
                            </div>
                          ))}
                        </div>

                        {/* Modal */}
                        {isOpen4 && selectedMedia && (
                            <Lightbox
                              mainSrc={
                                selectedMedia.media_type === "image"
                                  ? image_url2 + selectedMedia.media_file
                                  : image_url2 + selectedMedia.media_file
                              }
                              nextSrc={get_fte_media[(selectedIndex + 1) % get_fte_media.length].media_file}
                              prevSrc={
                                get_fte_media[
                                  (selectedIndex + get_fte_media.length - 1) % get_fte_media.length
                                ].media_file
                              }
                              onCloseRequest={closeModal}
                              onMovePrevRequest={showPreviousMedia}
                              onMoveNextRequest={showNextMedia}
                            />
                          )}
                    </div>

                    

              </Col>

              <Col xs={12} sm={4}>
                  <div>
                    <h4 class="fw-bold mt-4 setTitle">Farmer Sharing His Experience</h4>
                    <p className="text-muted text-justify setText1">Brief testimonial from farmer sharing his experience and feedback.</p>

                    <div>
                      <div>
                          {/* First video */}
                        
                          <div className="video-container" style={{ position: "relative", width: "95%", margin: "auto" }}>
                          



                          {/* Play/Pause button */}
                          <img
                            src={isPlaying ? pause_button : play_button} // Show correct button based on video state
                            alt={isPlaying ? 'Pause Button' : 'Play Button'}
                            className="SetplayButton"
                            style={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                              cursor: "pointer",
                              zIndex: 1,
                            }}
                            onClick={handlePlayButtonClick}
                          />

                          {/* Video element */}

                          
                          <video
                              id="videoPlayer"
                              ref={videoRef}
                              style={{ width: "100%", height: "auto", borderRadius: "10px" }}
                              controls={false} // Custom controls
                              onError={(e) => console.error('Failed to load video', e)} // Error handling
                            >
                              {/* <source
                                // src={
                                //   get_farmer_review_rating?.farmer_testimonial?.video_file
                                //     ? `https://bharatkrushiseva.in${get_farmer_review_rating.farmer_testimonial.video_file}?${Date.now()}` // Cache-busting
                                //     : "no loded" // Fallback video
                                // }

                                // src="https://bharatkrushiseva.in/media/CropVideoTour/videos/WhatsApp_Video_2024-09-14_at_18.13.15.mp4?1726497669654"
                              
                                type="video/mp4"
                              /> */}
                              Your browser does not support the video tag.
                            </video>


                        </div>
                      </div>

                      
                      <Row>
                        <Col xs={12} sm={6}>
                          <div
                            id="heartIcon"
                            onClick={handleLikeClick}
                            style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
                          >
                            {get_farmer_review_rating?.farmer_likes || 0} <FaHeart />
                          </div>
                        </Col>

                        <Col xs={12} sm={6}>
                          <div id="startIcon">
                            {get_farmer_review_rating?.average_rating || 0} Rating
                          </div>
                        </Col>
                        </Row>
                      {/* Provide Ratings */}
                      <h4 class="mt-4 setTitle">Provide Ratings</h4>
                      <p class="text-muted">Share your feedback on this farmer.</p>

                      <div>
                          {/* Star Rating Section */}
                          <div className="row">
                            <div className="col-lg-8">
                              <div className="star-rating" style={{ fontSize: '40px' }}>
                                {[...Array(5)].map((_, i) => (
                                  <span
                                    key={i + 1}
                                    className="star"
                                    onClick={() => handleStarClick(i + 1)}
                                    style={{ color: selectedRating >= i + 1 ? 'gold' : 'grey', cursor: 'pointer' }}
                                  >
                                    &#9733;
                                  </span>
                                ))}
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <button
                                onClick={handleSubmitRating}
                                style={{ marginTop: '10px' }}
                                className="btn btn-primary btn-sm"
                              >
                                Submit
                              </button>
                            </div>
                          </div>

                          {/* Review Section */}
                          {showReviewSection && (
                            <div id="reviewSection" style={{ marginTop: '10px' }}>
                              <h4 className="mt-4 setTitle">Add Your Review</h4>
                              <textarea
                                id="reviewText"
                                placeholder="Write your review here..."
                                rows="4"
                                style={{ width: '100%' }}
                                value={reviewText}
                                onChange={(e) => setReviewText(e.target.value)}
                              ></textarea>
                              <button
                                onClick={handleReviewSubmit}
                                style={{ marginTop: '10px' }}
                                className="btn btn-primary btn-sm"
                              >
                                Submit Review
                              </button>
                            </div>
                          )}
                      </div>


                      
                      

                    </div> 
                  </div>

                  <div>
                     {/* servey response */}
                  <h4 class="mt-4 setTitle">Farmer Survey Response</h4>
                  <p class="text-muted">View the farmer's answers to our survey questions for insight into their practices and needs.</p>
                  <div className="container">
                    {/* Loading state */}
                    {loading ? (
                      <p>Loading...</p>
                    ) : get_ques_data.length > 0 ? (
                      <div className="slide active-slide">
                        <div className="question">
                          {/* Dynamically display the question */}
                          <p className="mt-4 setTitle">
                            Question :-
                            {get_ques_data[activeQuestionIndex].question_id_fk.question}
                          </p>
                          <p className="mt-4 setTitle">
                            Ans :-
                            {get_ques_data[activeQuestionIndex].answer_id_fk.answer}
                          </p>
                        </div>
                        <div className="text-center">
                          {/* Conditionally render the image or fallback message */}
                          {get_ques_data[activeQuestionIndex].question_id_fk.has_image ? (
                            <img
                              src={`https://bharatkrushiseva.in${get_ques_data[activeQuestionIndex].question_id_fk.question_image}`}
                              alt="Question Image"
                            />
                          
                          ) : (
                            <h4>No images Available</h4>
                          )}
                        </div>
                        <div className="options text-center mt-3">
                          {[1, 2, 3, 4, 5].map((option) => (
                            <React.Fragment key={option}>
                              <button
                                className={`btn-option ${
                                  activeQuestionIndex + 1 === option ? "active" : ""
                                }`}
                                onClick={() => handleOptionClick(option)} // Handle option click
                              >
                                {option}
                              </button>
                              {option < 5 && <div className="line"></div>}
                            </React.Fragment>
                          ))}
                        </div>
                      </div>
                    ) : (
                      <p>No questions available</p>
                    )}
                  </div>
                  </div>



                <h4 class="mt-4 setTitle">Our Impact on Farmers</h4>
                <p class="text-muted">Discover the positive changes and benefits we've brought to farmers through our initiatives.</p>
                <div className="container mt-2">
                  <div className="row">
                    {/* Card 1: Audio & Video Calls */}
                    <div className="col-md-6">
                      <div className="card text-center mb-3" style={{ backgroundColor: '#EEF8FC', borderRadius: '15px' }}>
                        <div className="card-body">
                        <img src={image4_1} alt="Logo" className=" " />                              
                          <h5 className="card-title mt-3">{farmer_impact_summary.savings_expenses_percent}%</h5>
                          <p className="card-text TextSET setTitle">Savings on Expenses</p>
                        </div>
                      </div>
                    </div>

                    {/* Card 2: Weather Alerts */}
                    <div className="col-md-6">
                      <div className="card text-center mb-3" style={{ backgroundColor: '#FCF1DB', borderRadius: '15px',  }}>
                        <div className="card-body">
                        <img src={image4_2} alt="Logo" className=" " />
                          <h5 className="card-title mt-3 setNUmber">{farmer_impact_summary.increase_yield_percent}%</h5>
                          <p className="card-text TextSET setTitle">Increase In Yield</p>
                        </div>
                      </div>
                    </div>

                    {/* Card 3: Disease & Pest Alerts */}
                    <div className="col-md-6">
                      <div className="card text-center mb-3" style={{ backgroundColor: '#FCEEF2', borderRadius: '15px' }}>
                        <div className="card-body">
                          <img src={image4_3} alt="Logo" className=" " />
                          <h5 className="card-title mt-3 setNUmber setTitle ">{farmer_impact_summary.increase_income_percent}%</h5>
                          <p className="card-text TextSET setNUmber">Increase in Income</p>
                        </div>
                      </div>
                    </div>

                    
                   
                  </div>
                </div>

                <h4 class="mt-4 setTitle">Envirnomental Impact</h4>
                <p class="text-muted">Explore the position effects our practices have had on the environment.</p>
                <div className="container mt-2">
                  <div className="row">

                    {/* Card 1: Audio & Video Calls */}
                    <div className="col-md-6">
                      <div className="card text-center mb-3" style={{ backgroundColor: '#F3E4DC', borderRadius: '15px' }}>
                        <div className="card-body">
                        <img src={image1} alt="Logo" className=" " />                              
                          <h5 className="card-title mt-3">{get_envernement_impact.land_productivity_increase_percent}%</h5>
                          <p className="card-text TextSET setNUmber">Land Productivity Increase</p>
                        </div>
                      </div>
                    </div>

                    {/* Card 2: Weather Alerts */}
                    <div className="col-md-6">
                      <div className="card text-center mb-3" style={{ backgroundColor: '#D8E7C7', borderRadius: '15px' }}>
                        <div className="card-body">
                        <img src={image2_1} alt="Logo" className=" " />
                          <h5 className="card-title mt-3 setNUmber">{get_envernement_impact.reduce_in_crop_loss_percent}%</h5>
                          <p className="card-text TextSET setNUmber">Reduce In Crop Loss</p>
                        </div>
                      </div>
                    </div>

                    {/* Card 3: Disease & Pest Alerts */}
                    <div className="col-md-6 mt-2">
                      <div className="card text-center mb-3" style={{ backgroundColor: '#DAF2FD', borderRadius: '15px' }}>
                        <div className="card-body">
                          <img src={image3_1} alt="Logo" className=" " />
                          <h5 className="card-title mt-3 setNUmber">{get_envernement_impact.reduction_in_water_usage_percent}%</h5>
                          <p className="card-text TextSET setNUmber">Reduction In Water Usage</p>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                {/* communication */}


                    {/* How to add  */}
                    
                    <img
                        src={pom2}
                        alt="Pomegranate Salad"
                        className="img-fluid"
                        onClick={handleOpenModal}
                        style={{ cursor: "pointer" }} // Makes it clear that it's clickable
                      />

                    <h5 class="fw-bold setTitle">How to Store Whole Pomegranates</h5>
                    <p class="text-muted">
                        You can store whole pomegranates to maintain their tangy-sweet flavor. For short-term storage, keep them in a cool, dry place. For longer freshness, refrigerate the fruit to extend its shelf life.
                    </p>
                    

                    <img
                        src={pom}
                        alt="Pomegranate Salad"
                        className="img-fluid"
                        onClick={handleOpenModal}
                        style={{ cursor: "pointer" }} // Makes it clear that it's clickable
                      />
                    <h5 class="fw-bold setTitle">How Long Do Pomegranates Last?</h5>
                    <p class="text-muted">
                        Pomegranates last 1-2 weeks at room temperature and up to 2 months when refrigerated. Pomegranate seeds last 5-7 days in the fridge or can be frozen for up to a year for long-term storage.
                    </p>



                    <h5 class="fw-bold setTitle">Cooking with Pomegranates: Flavorful Recipe Ideas</h5>
                    <div>
                    <img
                        src={cooking_pomegranate2}
                        alt="Pomegranate Salad"
                        className="img-fluid"
                        onClick={handleOpenModal}
                        style={{ cursor: "pointer" }} // Makes it clear that it's clickable
                      />
                      <h5 class="fw-bold setTitle" className="SetColor mt-2">Pomegranate salad</h5>  
                      <p class="text-muted " >
                        Try this fruity salad for a light lunch or barbecue side. It's studded with jewel-like pomegranate seeds and is quick to prepare
                      </p>
                    </div>

                    
                    <div>
                    <img
                        src={cooking_pomegranate1}
                        alt="Pomegranate Salad"
                        className="img-fluid"
                        onClick={handleOpenModal1}
                        style={{ cursor: "pointer" }} // Makes it clear that it's clickable
                      />
                      <h5 class="fw-bold setTitle" className="SetColor mt-2">Homemade grenadine</h5>  
                      <p class="text-muted">
                        Blend your own grenadine with pomegran ate juice, sugar and orange blossom water to liven up lemonade and make your favourite cocktails and mocktails
                      </p>
                    </div>


                    <div>
                    <img
                        src={cooking_pomegranate3}
                        alt="Pomegranate Salad"
                        className="img-fluid"
                        onClick={handleOpenModal2}
                        style={{ cursor: "pointer" }} // Makes it clear that it's clickable
                      />
                      <h5 class="fw-bold setTitle" className="SetColor mt-2">Mulled pomegranate juice</h5>  
                      <p class="text-muted">
                        Look after all of your party guests, including the ones who aren't drinking with this sim ple, non-alcoholic Christmas-spiced fruit juice served warm
                      </p>
                    </div>
              
            
              </Col>

              <Col xs={12} sm={8}>
              <h5 class="fw-bold mt-5 setTitle">Talk To Us</h5>
                <p class="text-muted">We Value your input Please share your feedback to help us improve your dashboard experience.</p>
                <div className="container mt-5">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-md-6 form-group">
                        <p htmlFor="enquiryType">Select Enquiry Type</p>
                        <select
                          className="form-control"
                          id="enquiryType"
                          name="enquiryType"
                          value={formData.enquiryType}
                          onChange={handleChange}
                        >
                          <option value="">Select</option>
                          <option value="Features">Features</option>
                          <option value="Pricing">Pricing</option>
                          <option value="Support">Support</option>
                          <option value="Other">Other</option>
                        </select>
                        {formErrors.enquiryType && <small style={{ color: 'red' }}>{formErrors.enquiryType}</small>}
                      </div>

                      <div className="col-md-6 form-group">
                        <p htmlFor="fullName">Full Name</p>
                        <input
                          type="text"
                          className="form-control"
                          name="fullName"
                          id="fullName"
                          value={formData.fullName}
                          onChange={handleChange}
                          placeholder="Enter your full name"
                        />
                        {formErrors.fullName && <small style={{ color: 'red' }}>{formErrors.fullName}</small>}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6 form-group">
                        <p htmlFor="email">Email</p>
                        <input
                          type="email"
                          className="form-control"
                          name="email"
                          id="email"
                          value={formData.email}
                          onChange={handleChange}
                          placeholder="Enter your email"
                        />
                        {formErrors.email && <small style={{ color: 'red' }}>{formErrors.email}</small>}
                      </div>

                      <div className="col-md-6 form-group">
                        <p htmlFor="country">Country</p>
                        <input
                          type="text"
                          className="form-control"
                          name="country"
                          id="country"
                          value={formData.country}
                          onChange={handleChange}
                          placeholder="Enter your country"
                        />
                        {formErrors.country && <small style={{ color: 'red' }}>{formErrors.country}</small>}
                      </div>
                    </div>

                    <div className="form-group">
                      <p htmlFor="message">Message</p>
                      <textarea
                        className="form-control"
                        name="message"
                        id="message"
                        rows="4"
                        value={formData.message}
                        onChange={handleChange}
                        placeholder="Enter your message here"
                      />
                      {formErrors.message && <small style={{ color: 'red' }}>{formErrors.message}</small>}
                    </div>

                    <div className="form-group form-check">
                      <input
                        type="checkbox"
                        name="check_subscribe"
                        className="form-check-input"
                        id="subscribe"
                        checked={formData.checkSubscribe}
                        onChange={handleChange}
                      />
                      <label className="form-check-label" htmlFor="subscribe">
                        I would like to subscribe to Bharat Krushi Seva
                      </label>
                    </div>

                    <button type="submit" className="btn btn-submit btn-primary btn-sm">
                      SEND MESSAGE
                    </button>
                  </form>
                </div>
              </Col>
              
              {/* communication */}
              

              <hr class="custom-hr"/>
              
            </Row>
          </Container>

        {/* new page dessign start */}

       </div>

       <Footer />

      
    </div>
    

    


  );
};

export default Home;
